import authServices from "../services/authServices";
import React, { useEffect, useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Spinner } from "reactstrap";

import Index from "views/Index.js";
import Profile from "views/examples/Profile.js";
import Maps from "views/examples/Maps.js";
import Register from "views/examples/Register.js";
import Login from "views/examples/Login.js";
import Tables from "views/examples/Tables.js";
import Icons from "views/examples/Icons.js";
import Create from "views/examples/Create.js";
import Edit from "views/examples/Edit.js";

const PrivateRoute = ({
  component: component,
  isTrue: isTrue,
  setIsTrue: setIsTrue,
  isReady: isReady,
  setIsReady: setIsReady,
  setUser: setUser,
  user: user,
  path: path,
  name: name,
  ...rest
}) => {
  useEffect(() => {
    checkAuth();
  }, []);

  const checkAuth = async () => {
    if (isTrue) {
      setUser(res.user);
      setIsReady(true);
    }
    let res = await authServices.checkAuth();
    if (res.success) {
      setUser(res.user);
      setIsTrue(true);
    }
    setIsReady(true);
  };

  const getRoute = () => {
    if (name == "Tables") {
      return <Route path={path} render={(props) => <Tables {...props} user={user} />} />;
    } else if (name == "Create") {
      return <Route path={path} render={(props) => <Create {...props} user={user} />} />;
    } else if (name == "Edit") {
      return <Route path={path} render={(props) => <Edit {...props} user={user} />} />;
    }
  };

  return isReady ? (
    isTrue ? (
      getRoute()
    ) : (
      <Redirect
        to={{
          pathname: "/auth/login",
        }}
      />
    )
  ) : (
    <div style={{ height: "600px", marginTop: "-200px", marginLeft: "-200px", width: "400px" }}>
      <Spinner
        color="danger"
        style={{
          width: "3rem",
          height: "3rem",
          position: "sticky",
          top: "50%",
          left: "50%",
        }}
      />
    </div>
  );
};

export default PrivateRoute;
