import axios from "axios";

export default {
  getAll: async (user) => {
    console.log(user);
    let res = await axios.post(`/api/events`, { user: user });
    return res.data || [];
  },
  get: async (id) => {
    let res = await axios.get(`/api/product/${id}`);
    return res.data || [];
  },
  getOne: async (id) => {
    let res = await axios.get(`/api/event/${id}`);
    return res.data || [];
  },
  update: async (id, product) => {
    let res = await axios.put(`/api/product/${id}`, product);
    return res.data || [];
  },
  create: async (event) => {
    let res = await axios.post(`/api/event`, event);
    return res.data || [];
  },
  updateSubscription: async (subscription) => {
    let res = await axios.post(`/api/subscription`, subscription);
    return res.data || [];
  },
  delete: async (id) => {
    let res = await axios.delete(`/api/event/${id}`);
    return res.data || [];
  },
};
