import React, { useEffect, useState } from "react";
import eventServices from "../services/eventServices.js";
import { Table, Button, Alert } from "reactstrap";
import styled, { keyframes } from "styled-components";
import { fadeIn } from "react-animations";
import { CSSTransitionGroup } from "react-transition-group";
import { motion } from "framer-motion";

const List2 = (props) => {
  const { product, setProduct, setNewName, newName, listType, setNeedUpdate } = props;
  const [sub, setSub] = useState(false);
  const fadeAnimation = keyframes`${fadeIn}`;

  const IOS = () => {
    return (
      ["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  };

  const variants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };
  const [share, setShare] = useState(true);
  const [alert, setAlert] = useState(false);
  const [disabledNotify, setDisabledNotify] = useState(false);

  const handleAlert = (message) => {
    setAlert(message);
    setTimeout(() => {
      setAlert(false);
    }, 4000);
  };

  const updateAddEvent = async (e) => {
    e.preventDefault();
    if (listType === "No") {
      const newArray = [{ name: newName, verified: false }, ...product.nos];
      setProduct((product) => ({ ...product, nos: newArray }));
      console.log(product);
      setUpdate();
      setNewName("");
    } else {
      const newArray = [{ name: newName, verified: false }, ...product.yess];
      setProduct((product) => ({ ...product, yess: newArray }));
      setUpdate();
      setNewName("");
    }
  };

  const updateSubscription = async () => {
    console.log("hi");
    /* Notification service worker check */
    const check = () => {
      if (!("serviceWorker" in navigator)) {
        throw new Error("No Service Worker support!");
      }
      if (!("PushManager" in window)) {
        throw new Error("No Push API Support!");
      }
    };

    const registerServiceWorker = async () => {
      const swUrl = `${process.env.PUBLIC_URL}/sw-pushv1.js`;
      console.log("swUrl", swUrl);

      const swRegistration = await navigator.serviceWorker.register(swUrl, {
        scope: "/worker/",
      });
      return swRegistration;
    };

    const update = async () => {
      console.log("update event");
      let split = localStorage.getItem("event");
      const id = window.location.href.substring(window.location.href.lastIndexOf("/") + 1);
      if (split) {
        localStorage.setItem("event", split + id + "|");
      } else {
        localStorage.setItem("event", id + "|");
      }

      let subscription = localStorage.getItem("subscription");
      let data = { event: id, subscription: subscription };
      setDisabledNotify(true);
      let res = await eventServices.updateSubscription(data);
      console.log(res);
    };

    const main = async () => {
      check();

      const askPermission = async () => {
        return new Promise(function (resolve, reject) {
          const permissionResult = Notification.requestPermission(function (result) {
            resolve(result);
          });

          if (permissionResult) {
            permissionResult.then(resolve, reject);
          }
        }).then(function (permissionResult) {
          if (permissionResult !== "granted") {
            throw new Error("We weren't granted permission.");
          }
        });
      };

      const register = async (permission) => {
        if (Notification.permission == "granted") {
          const swRegistration = await registerServiceWorker();
          console.log("swReg", swRegistration);

          let reg = await navigator.serviceWorker.getRegistration("/worker/");
          reg.update();
          return reg;

          /*navigator.serviceWorker.getRegistration("/worker/").then((reg) => {
            reg.pushManager.getSubscription().then((sub) => {
              console.log(sub);
              if (sub === null) {
                reg.update();
              } else {
                // We have a subscription, update the database
                console.log(sub.endpoint);
                if (localStorage.getItem("subscription")) {
                  console.log(localStorage.getItem("subscription"));
                } else {
                  localStorage.setItem("subscription", sub.endpoint);
                }
              }
            
            console.log("About to show notification", reg);
            console.log(reg);
            //reg.showNo`tification("You are subscribed to this signup!");
          });
          });*/

          // navigator.serviceWorker.ready.then(function(reg) {
          //   new Notification("Helo");
          // });
        }
      };

      const permission = await askPermission();
      let reg = await register(permission);
      setTimeout(async () => {
        let sub = await reg.pushManager.getSubscription();
        reg.update();
        if (localStorage.getItem("subscription")) {
          console.log(localStorage.getItem("subscription"));
        } else {
          localStorage.setItem("subscription", sub.endpoint);
        }
      }, 3000);
    };

    main();

    if (Notification.permission == "granted") {
      console.log("hit");
      await update();
    } else {
      console.log("hit");
      await main();
      setTimeout(async () => {
        await update();
      }, 5000);
    }

    await handleAlert("Sounds good!  You will be notified whenever there is an important update.");
  };

  useEffect(() => {
    if (localStorage.getItem("event")) {
      let split = localStorage.getItem("event").split("|");
      split.forEach((event) => {
        console.log(event);
        const id = window.location.href.substring(window.location.href.lastIndexOf("/") + 1);
        if (event.length > 0) {
          if (event === id) {
            setDisabledNotify(true);
          }
        }
      });
    } else {
      setDisabledNotify(false);
    }
  }, [localStorage.getItem("subscription")]);

  /*  const updateEditEvent = async () => {
    const id = window.location.href.substring(window.location.href.lastIndexOf("/") + 1);
    await eventServices.update(id, product);
    setNewName("");
  };*/

  const addNameChange = (e) => {
    setNewName(e.target.value);
  };

  const changeName = (e) => {
    if (listType === "No") {
      let oldArray = product.nos;
      console.log(oldArray);
      let name = e.target.name;
      let newArray = oldArray
        .map((no) => {
          if (!no) {
            return null;
          } else if (no.name === name) {
            if (e.target.value == "") {
              setUpdate();
              return null;
            } else {
              return { ...no, name: e.target.value };
            }
          }

          return no;
        })
        .filter(Boolean);
      setProduct((product) => ({ ...product, nos: newArray }));
    } else {
      let oldArray = product.yess;
      console.log(oldArray);
      let name = e.target.name;
      let newArray = oldArray
        .map((yes) => {
          if (!yes) {
            return null;
          } else if (yes.name === name) {
            if (e.target.value == "") {
              setUpdate();
              return null;
            } else {
              return { ...yes, name: e.target.value };
            }
          }

          return yes;
        })
        .filter(Boolean);
      setProduct((product) => ({ ...product, yess: newArray }));
    }
  };

  const dropShare = () => {
    setShare(false);
  };

  const setUpdate = () => {
    setNeedUpdate(true);
    setShare(true);
  };

  return (
    <motion.div initial="hidden" animate="visible" variants={variants} transition={{ ease: "easeIn", duration: 0.5 }}>
      <Table hover>
        <tbody>
          <tr>
            <td style={{ textAlign: "center" }}>
              <form style={{ display: "block" }} onSubmit={(e) => updateAddEvent(e)}>
                <input
                  style={{
                    background: "none",
                    textAlign: "center",
                    margin: "auto",
                    height: "25px",
                    border: "none",
                    color: "#625A5A",
                    fontSize: "15px",
                    fontWeight: "600",
                    display: "inline",
                    width: "135px",
                  }}
                  placeholder="Type your name"
                  name="name"
                  onFocus={dropShare}
                  onChange={addNameChange}
                  value={newName}
                  onBlur={() => setShare(true)}
                />
                <Button color="danger" style={{ backgroundColor: "#E80E0E" }} disabled={newName.length === 0} type="submit" value="Add">
                  Add
                </Button>
              </form>
            </td>
          </tr>
          {listType === "No"
            ? product.nos.map((person, index) =>
                person ? (
                  <tr>
                    <td style={{ textAlign: "center" }}>
                      <motion.div initial="hidden" animate="visible" variants={variants}>
                        <input
                          style={{
                            display: "inline",
                            background: "none",
                            textAlign: "center",
                            margin: "auto",
                            height: "25px",
                            border: "none",
                            color: "#625A5A",
                            fontSize: "15px",
                            fontWeight: "600",
                          }}
                          onFocus={dropShare}
                          onBlur={setUpdate}
                          onChange={changeName}
                          name={person.name}
                          value={person.name}
                        />
                      </motion.div>
                    </td>
                  </tr>
                ) : null
              )
            : product.yess.map((person) =>
                person ? (
                  <tr>
                    <td style={{ textAlign: "center" }}>
                      <input
                        style={{
                          display: "inline",

                          background: "none",
                          textAlign: "center",
                          margin: "auto",
                          height: "30px",
                          color: "#625A5A",
                          border: "none",
                          fontSize: "15px",
                          fontWeight: "600",
                        }}
                        onBlur={setUpdate}
                        onFocus={dropShare}
                        onChange={changeName}
                        name={person.name}
                        value={person.name}
                      />
                    </td>
                  </tr>
                ) : null
              )}
          {share ? (
            <div
              style={{
                backgroundColor: "white",
                display: "block",
                position: "fixed",
                padding: "10px 10px",
                bottom: "0px",
                zIndex: "1",
                width: "100%",
                left: "0%",
                boxShadow: "0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)",
              }}
            >
              {!IOS() ? (
                <div style={{ width: "30%", display: "inline-block", textAlign: "left" }}>
                  <Button
                    disabled={disabledNotify}
                    onClick={() => {
                      updateSubscription();
                    }}
                  >
                    <i className="fas fa-bell" /> Notify
                  </Button>
                </div>
              ) : null}
              {!IOS() ? (
                <div style={{ width: "70%", display: "inline-block", textAlign: "right" }}>
                  <Button
                    color="success"
                    style={{ width: "90%" }}
                    onClick={() => {
                      navigator.clipboard.writeText("You're Invited!  https://signups.lifenaction.com/" + product.signUpId);
                      handleAlert("Link has been copied.  Share with your friends!");
                    }}
                  >
                    <i className="fas fa-share" /> Share
                  </Button>
                </div>
              ) : (
                <div style={{ width: "100%", display: "inline-block", textAlign: "center" }}>
                  <Button
                    color="success"
                    style={{ width: "90%" }}
                    onClick={() => {
                      navigator.clipboard.writeText("You're Invited!  https://signups.lifenaction.com/" + product.signUpId);
                      handleAlert("Link has been copied.  Share with your friends!");
                    }}
                  >
                    <i className="fas fa-share" /> Share
                  </Button>
                </div>
              )}
              {/*<div style={{ width: "65%", display: "inline-block" }}>
              <Button
                style={{
                  fontWeight: "900",
                  fontSize: "16px",
                  padding: "8px",
                  width: "100%",
                }}
                color="success"
                size="lg"
                type="submit"
                onClick={updateEditEvent}
              >
                Saved &nbsp;
                <i className="fas fa-check" />
              </Button>
              </div>*/}
            </div>
          ) : null}
        </tbody>
      </Table>
      {alert ? (
        <Alert
          style={{
            position: "fixed",
            margin: "20px",
            top: "20px",
            left: "0",
            zIndex: "1",
          }}
          color="success"
        >
          {alert}
        </Alert>
      ) : null}
    </motion.div>
  );
};

export default List2;
