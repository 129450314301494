/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { GoogleLogin } from "react-google-login";
import authServices from "../../services/authServices.js";
import { useHistory } from "react-router-dom";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Button,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import { Redirect } from "react-router-dom";

const Login = () => {
  let history = useHistory();
  const responseGoogle = async (response) => {
    console.log(response);
    let res = await authServices.signIn(response.tokenId);
    if (res.success) {
      history.push("/admin");
    }
  };

  return (
    <>
      <Col lg="5" md="7">
        <div className="text-center">
          <GoogleLogin
            clientId="239355603126-f7sanoltfskb2tj3hu99ephmhq4otm4g.apps.googleusercontent.com"
            render={(renderProps) => (
              <Button
                size="lg"
                outline
                style={{ color: "white", border: "solid white" }}
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
              >
                Login With Google
              </Button>
            )}
            buttonText="Login"
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            cookiePolicy={"single_host_origin"}
          />
        </div>
        {/*<Card className="bg-secondary shadow border-0">
          <CardHeader>
            <div className="btn-wrapper text-center">
              <div className="mb-5">
                <img src={require("assets/img/brand/logo-hub.png")} alt="" style={{ width: "80%" }} />
              </div>
              <GoogleLogin
                clientId="239355603126-f7sanoltfskb2tj3hu99ephmhq4otm4g.apps.googleusercontent.com"
                render={(renderProps) => (
                  <Button
                    block
                    size="lg"
                    outline
                    style={{ color: "white", border: "solid thick white" }}
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                  >
                    Login With Google
                  </Button>
                )}
                buttonText="Login"
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                cookiePolicy={"single_host_origin"}
              />
            </div>
          </CardHeader>
          {/*<CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Or sign in with credentials</small>
            </div>
            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Email" type="email" autoComplete="new-email" />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Password" type="password" autoComplete="new-password" />
                </InputGroup>
              </FormGroup>
              <div className="custom-control custom-control-alternative custom-checkbox">
                <input className="custom-control-input" id=" customCheckLogin" type="checkbox" />
                <label className="custom-control-label" htmlFor=" customCheckLogin">
                  <span className="text-muted">Remember me</span>
                </label>
              </div>
              <div className="text-center">
                <Button className="my-4" color="primary" type="button">
                  Sign in
                </Button>
              </div>
            </Form>
                </CardBody>
        </Card>*/}
        {/*<Row className="mt-3">
          <Col xs="6">
            <a className="text-light" href="#pablo" onClick={(e) => e.preventDefault()}>
              <small>Forgot password?</small>
            </a>
          </Col>
          <Col className="text-right" xs="6">
            <a className="text-light" href="#pablo" onClick={(e) => e.preventDefault()}>
              <small>Create new account</small>
            </a>
          </Col>
              </Row>*/}
      </Col>
    </>
  );
};

export default Login;
