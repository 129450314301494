/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import PrivateRoute from "components/PrivateRoute";
import Sidebar from "components/Sidebar/Sidebar.js";
import authServices from "../services/authServices";

import routes from "routes.js";

const Admin = (props) => {
  const [isTrue, setIsTrue] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [user, setUser] = useState("");
  const [name, setName] = useState(false);

  useEffect(() => {
    console.log(routes);
  }, []);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <PrivateRoute
            path={prop.layout + prop.path}
            isTrue={isTrue}
            isReady={isReady}
            setIsReady={setIsReady}
            setIsTrue={setIsTrue}
            component={prop.component}
            name={prop.componentName}
            key={key}
            user={user}
            setUser={setUser}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (props.location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  return (
    <>
      <Sidebar
        {...props}
        routes={routes}
        user={user}
        logo={{
          innerLink: "/admin/index",
          imgSrc: require("assets/img/brand/logo-hub1.png"),
          imgAlt: "...",
        }}
      />
      <div className="main-content">
        <AdminNavbar {...props} user={user} brandText={getBrandText(props.location.pathname)} />
        <Switch>
          {getRoutes(routes)}
          <Redirect from="*" to="/admin/index" />
        </Switch>
        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
    </>
  );
};

export default Admin;
