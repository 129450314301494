/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
import SemiCircleProgressBar from "react-progressbar-semicircle";
import eventServices from "../../services/eventServices.js";
import List2 from "../../components/List2";
import classnames from "classnames";
import authServices from "../../services/authServices.js";

import { CSSTransitionGroup } from "react-transition-group";

// reactstrap components
import {
  Button,
  Card,
  CardTitle,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  NavItem,
  NavLink,
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import { motion } from "framer-motion";
import moment from "moment";

const Event = (props) => {
  const { buttonLabel, className } = props;

  const variants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  const [modal, setModal] = useState(false);
  const [replyModal, setReplyModal] = useState(false);
  const [featuredComment, setFeaturedComment] = useState(null);
  const [dummyState, setDummyState] = useState(null);

  const toggleModal = () => setModal(!modal);

  const toggleReply = (id) => {
    setReplyModal(!replyModal);
    let res = event.comments.filter((comment) => {
      return comment._id == id;
    });
    setFeaturedComment(res);
  };

  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
    setDropdown(false);
  };
  const [needUpdate, setNeedUpdate] = useState(false);
  const [event, setEvent] = useState(null);
  const [nos, setNos] = useState(null);
  const [newName, setNewName] = useState("");
  const [yesChart, setYesChart] = useState(null);
  const [noChart, setnoChart] = useState(null);
  const [message, setMessage] = useState([]);
  const [dropdown, setDropdown] = useState(false);

  useEffect(() => {
    if (!event) {
      setEvent(props.event);
    }
    setCharts(props.event);
  });

  /*const checkAuth = async () => {
    let res = await authServices.checkAuth();
    if (res.success) {
      console.log(res.user);
    }
  };*/

  const toggleDropdown = () => {
    setDropdown(!dropdown);
  };

  useEffect(() => {
    console.log(props.event);
    if (needUpdate) {
      updateEditEvent();
      setNeedUpdate(false);
    }
    setTimeout(() => {
      props.getEvents();
    }, 3000);
  }, [needUpdate]);

  const addMessage = async (e) => {
    e.preventDefault();
    const newArray = event.comments.concat({ text: message.text, author: message.author, replies: [] });
    setEvent((event) => ({ ...event, comments: newArray }));

    setNeedUpdate(true);
  };

  const addReplyMessage = async (e) => {
    e.preventDefault();
    let index = featuredComment.map((object) => {
      return event.comments.findIndex((x) => x._id == object._id);
    });
    let middleEvent = event;
    await middleEvent.comments[index].replies.push({ text: message.text, author: message.author });

    setEvent((event) => ({ ...event, comments: middleEvent.comments }));

    setNeedUpdate(true);
  };

  const updateEditEvent = async () => {
    const id = props.event._id;
    await eventServices.update(id, event);
    setMessage(null);
    setNewName("");
  };

  const handleChange = (e) => {
    setMessage({ ...message, [e.target.name]: e.target.value });
  };

  const setCharts = async (event) => {
    let sum = event.yess.length + event.nos.length;

    let yesPercent = event.yess.length / sum;
    let noPercent = event.nos.length / sum;

    let yes = yesPercent * 100;
    console.log(yes);
    let no = noPercent * 100;
    console.log(no);
    if (!yes || yes === 0) {
      let yes = 5;
      setYesChart(yes);
    } else {
      setYesChart(yes);
    }
    if (!no || no === 0) {
      let no = 5;
      console.log(no);
      setnoChart(no);
    } else {
      setnoChart(no);
    }
  };

  return (
    <>
      {props.index === props.active ? (
        <>
          <UserHeader event={event} />
          {/* Page content */}
          {event ? (
            <Container className="mt--7" fluid>
              <Row>
                <Col className=" mb-5 mb-xl-0" xl="6">
                  <Card className="card-profile shadow">
                    <Row>
                      <div className="col">
                        <div className="card-profile-stats d-flex justify-content-center mt-md-5" style={{ paddingBottom: "0px" }}>
                          <div>
                            <SemiCircleProgressBar
                              background={"#dee2e6"}
                              percentage={yesChart}
                              showPercentValue
                              showPercentValue={false}
                              diameter={100}
                              stroke={"#2dce89"}
                            />

                            <div style={{ marginTop: "-15px" }}>
                              <span className="heading">{event ? event.yess.length : null}</span>
                              <span className="description">Yes</span>
                            </div>
                          </div>
                          <div>
                            <SemiCircleProgressBar
                              percentage={noChart}
                              showPercentValue={false}
                              direction={"left"}
                              diameter={100}
                              stroke={"#FFD600"}
                              background={"#dee2e6"}
                            />
                            <div style={{ marginTop: "-15px" }}>
                              <span className="heading">{event ? event.nos.length : null}</span>
                              <span className="description">Maybe</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Row>

                    <div className="text-center mb-4  ">
                      <h3 className="mb-0 mt-3" style={{ color: "#333" }}>
                        Are you coming?
                      </h3>
                      <span className="description" style={{ fontSize: "13px", color: "grey" }}>
                        Let the organizer know!
                      </span>
                    </div>

                    {/*<Row className="justify-content-center">
                  <Col className="order-lg-2" lg="3">
                    <div className="card-profile-image">
                      <a href="#pablo" onClick={e => e.preventDefault()}>
                        <img
                          alt="..."
                          className="rounded-circle"
                          src={require("assets/img/theme/team-4-800x800.jpg")}
                        />
                      </a>
                    </div>
                  </Col>
                </Row>*/}
                    {/*<CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                  <div className="d-flex justify-content-between">
                    <Button className="mr-4" color="info" href="#pablo" onClick={(e) => e.preventDefault()} size="sm">
                      Connect
                    </Button>
                    <Button className="float-right" color="default" href="#pablo" onClick={(e) => e.preventDefault()} size="sm">
                      Message
                    </Button>
                  </div>
              </CardHeader>*/}
                    <CardBody className="pt-0 pt-md-4">
                      <Nav style={{ boxShadow: "0 0 2rem 0 rgba(136, 152, 170, 0.15)" }} tabs>
                        <NavItem style={{ width: "50%", textAlign: "center" }}>
                          <NavLink
                            style={activeTab === "1" ? { backgroundColor: "#2DCE89", border: "none" } : null}
                            className={classnames({ active: activeTab === "1" })}
                            onClick={() => {
                              toggle("1");
                            }}
                          >
                            {activeTab === "1" ? <i className="fas fa-check" style={{ color: "white" }}></i> : null}{" "}
                            <strong
                              style={activeTab === "1" ? { color: "white", fontWeight: "600" } : { color: "grey", fontWeight: "600" }}
                            >
                              {" "}
                              Yes
                            </strong>
                          </NavLink>
                        </NavItem>
                        <NavItem style={{ width: "50%", textAlign: "center" }}>
                          <NavLink
                            style={activeTab === "2" ? { backgroundColor: "#ffc93c", border: "none" } : null}
                            className={classnames({ active: activeTab === "2" })}
                            onClick={() => {
                              toggle("2");
                            }}
                          >
                            {activeTab === "2" ? <i className="fas fa-check" style={{ color: "white" }}></i> : null}
                            <span style={activeTab === "2" ? { color: "white", fontWeight: "600" } : { color: "grey", fontWeight: "600" }}>
                              {" "}
                              Maybe
                            </span>
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                          <Row>
                            <Col sm="12">
                              <div
                                style={
                                  event
                                    ? !dropdown
                                      ? { paddingTop: "5px", maxHeight: "330px", transition: "max-height 1s ease-out", overflow: "hidden" }
                                      : { paddingTop: "5px", maxHeight: event.yess.length * 100, transition: "max-height 1s ease-out" }
                                    : null
                                }
                                className={event ? (event.yess.length > 3 ? (!dropdown ? "list" : null) : null) : null}
                              >
                                {event ? (
                                  <List2
                                    listType="Yes"
                                    product={event}
                                    setNeedUpdate={setNeedUpdate}
                                    setProduct={setEvent}
                                    newName={newName}
                                    setNewName={setNewName}
                                  />
                                ) : null}
                              </div>
                            </Col>
                          </Row>
                        </TabPane>

                        <TabPane tabId="2">
                          <motion.div initial="hidden" animate="visible" variants={variants}>
                            <Row>
                              <Col sm="12">
                                <div
                                  style={
                                    event
                                      ? !dropdown
                                        ? {
                                            paddingTop: "5px",
                                            maxHeight: "240px",
                                            transition: "max-height 1s ease-out",
                                            overflow: "hidden",
                                          }
                                        : { paddingTop: "5px", maxHeight: event.nos.length * 100, transition: "max-height 1s ease-out" }
                                      : null
                                  }
                                  className={event ? (event.nos.length > 3 ? (!dropdown ? "list" : null) : null) : null}
                                >
                                  {event ? (
                                    <List2
                                      listType="No"
                                      product={event}
                                      setProduct={setEvent}
                                      setNeedUpdate={setNeedUpdate}
                                      newName={newName}
                                      setNewName={setNewName}
                                    />
                                  ) : null}
                                </div>
                              </Col>
                            </Row>
                          </motion.div>
                        </TabPane>
                        {event ? (
                          event.yess.length > 3 ? (
                            activeTab == "1" ? (
                              <div
                                style={{
                                  boxShadow: "0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)",
                                  position: "absolute",
                                  left: "44%",
                                  backgroundColor: "#E80E0E",
                                }}
                                onClick={toggleDropdown}
                                className="icon icon-shape bg-warning text-white rounded-circle"
                              >
                                {!dropdown ? <i className="ni ni-bold-down" /> : <i className="ni ni-bold-up" />}
                              </div>
                            ) : null
                          ) : null
                        ) : null}
                        {event ? (
                          event.nos.length > 3 ? (
                            activeTab == "2" ? (
                              <div
                                style={{
                                  boxShadow: "0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)",
                                  position: "absolute",
                                  left: "44%",
                                  backgroundColor: "#E80E0E",
                                }}
                                onClick={toggleDropdown}
                                className="icon icon-shape bg-warning text-white rounded-circle"
                              >
                                {!dropdown ? <i className="ni ni-bold-down" /> : <i className="ni ni-bold-up" />}
                              </div>
                            ) : null
                          ) : null
                        ) : null}
                      </TabContent>

                      {/*<div className="text-center">
                  <h3>
                    Jessica Jones
                    <span className="font-weight-light">, 27</span>
                  </h3>
                  <div className="h5 font-weight-300">
                    <i className="ni location_pin mr-2" />
                    Bucharest, Romania
                  </div>
                  <div className="h5 mt-4">
                    <i className="ni business_briefcase-24 mr-2" />
                    Solution Manager - Creative Tim Officer
                  </div>
                  <div>
                    <i className="ni education_hat mr-2" />
                    University of Computer Science
                  </div>
                  <hr className="my-4" />
                  <p>
                    Ryan — the name taken by Melbourne-raised, Brooklyn-based Nick Murphy — writes, performs and records all of his own
                    music.
                  </p>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    Show more
                  </a>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col className="order-xl-1" xl="8">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">My account</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button color="primary" href="#pablo" onClick={(e) => e.preventDefault()} size="sm">
                      Settings
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <h6 className="heading-small text-muted mb-4">User information</h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-username">
                            Username
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue="lucky.jesse"
                            id="input-username"
                            placeholder="Username"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-email">
                            Email address
                          </label>
                          <Input className="form-control-alternative" id="input-email" placeholder="jesse@example.com" type="email" />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-first-name">
                            First name
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue="Lucky"
                            id="input-first-name"
                            placeholder="First name"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-last-name">
                            Last name
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue="Jesse"
                            id="input-last-name"
                            placeholder="Last name"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
 
                  <h6 className="heading-small text-muted mb-4">Contact information</h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-address">
                            Address
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue="Bld Mihail Kogalniceanu, nr. 8 Bl 1, Sc 1, Ap 09"
                            id="input-address"
                            placeholder="Home Address"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-city">
                            City
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue="New York"
                            id="input-city"
                            placeholder="City"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-country">
                            Country
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue="United States"
                            id="input-country"
                            placeholder="Country"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-country">
                            Postal code
                          </label>
                          <Input className="form-control-alternative" id="input-postal-code" placeholder="Postal code" type="number" />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />

                  <h6 className="heading-small text-muted mb-4">About me</h6>
                  <div className="pl-lg-4">
                    <FormGroup>
                      <label>About Me</label>
                      <Input
                        className="form-control-alternative"
                        placeholder="A few words about you ..."
                        rows="4"
                        defaultValue="A beautiful Dashboard for Bootstrap 4. It is Free and
                          Open Source."
                        type="textarea"
                      />
                    </FormGroup>
                  </div>
                    </Form>*/}
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="6">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody style={{ boxShadow: "0 0 2rem 0 rgba(136, 152, 170, 0.15)" }}>
                      <Row>
                        <div className="col">
                          <CardTitle tag="h5" className=" mb-0" style={{ color: "#333" }}>
                            Questions, Comments, and Trash Talking &#128527;
                          </CardTitle>
                        </div>
                        <Col className="col-auto">
                          <div
                            style={{
                              boxShadow: "0 4px 6px rgba(50, 50, 93, 0.15), 0 1px 3px rgba(0, 0, 0, 0.08)",
                              backgroundColor: "#E80E0E",
                            }}
                            className="icon icon-shape text-white rounded-circle"
                            onClick={toggleModal}
                          >
                            <i className="fas fa-comment-dots" />
                          </div>
                          <Modal isOpen={modal} toggle={toggleModal} className={className}>
                            <ModalHeader toggle={toggleModal}>Create your message</ModalHeader>
                            <Form
                              onSubmit={(e) => {
                                addMessage(e);
                                toggleModal();
                              }}
                            >
                              <ModalBody>
                                <FormGroup>
                                  <Input type="text" onChange={handleChange} name="author" id="exampleEmail" placeholder="Author" />
                                </FormGroup>
                                <Input
                                  type="textarea"
                                  onChange={handleChange}
                                  style={{ height: "50px !important" }}
                                  name="text"
                                  placeholder="Type your message"
                                  id="exampleText"
                                />
                              </ModalBody>
                              <ModalFooter>
                                <Button color="warning" style={{ backgroundColor: "#E80E0E" }}>
                                  <i className="fas fa-comment-dots" />
                                </Button>{" "}
                                <Button color="secondary" onClick={toggleModal}>
                                  Cancel
                                </Button>
                              </ModalFooter>
                            </Form>
                          </Modal>
                          <Modal isOpen={replyModal} toggle={toggleReply} className={className}>
                            <ModalHeader toggle={toggleReply}>Create your message</ModalHeader>
                            <Form
                              onSubmit={(e) => {
                                addReplyMessage(e, featuredComment._id);
                                toggleReply();
                              }}
                            >
                              <ModalBody>
                                {featuredComment ? (
                                  featuredComment.map((object, index) => (
                                    <p id={index} className="mt-1 mb-4 text-sm comment">
                                      {object.text}
                                      <br />
                                      <span style={{ color: "lightgrey" }}>{object.author}</span>
                                    </p>
                                  ))
                                ) : (
                                  <p>Something went wrong</p>
                                )}

                                <FormGroup>
                                  <Input type="text" onChange={handleChange} name="author" id="exampleEmail" placeholder="Author" />
                                </FormGroup>
                                <Input
                                  type="textarea"
                                  onChange={handleChange}
                                  style={{ height: "100px !important" }}
                                  name="text"
                                  placeholder="Type your message"
                                  id="exampleText"
                                />
                              </ModalBody>
                              <ModalFooter>
                                <Button color="warning" style={{ backgroundColor: "#E80E0E" }}>
                                  <i className="fas fa-comment-dots" />
                                </Button>{" "}
                                <Button color="secondary" onClick={toggleReply}>
                                  Cancel
                                </Button>
                              </ModalFooter>
                            </Form>
                          </Modal>
                        </Col>
                      </Row>
                      <div>
                        <CSSTransitionGroup transitionName="example" transitionEnterTimeout={500} transitionLeaveTimeout={300}>
                          {event ? (
                            event.comments.length > 0 ? (
                              event.comments.map((object, index) => (
                                <div>
                                  <p id={index} className="mt-3 mb-0 text-sm comment">
                                    {object.text}
                                    <br />
                                    <span style={{ color: "lightgrey" }}>
                                      {object.author} &nbsp; <i onClick={() => toggleReply(object._id)} class="fas fa-reply"></i>
                                    </span>
                                  </p>
                                  {object.replies.length > 0
                                    ? object.replies.map((o, index) => (
                                        <ul>
                                          <p id={index} className="mt-3 mb-0 text-sm reply">
                                            {o.text}
                                            <br />
                                            <span style={{ color: "lightgrey" }}>{o.author}</span>
                                          </p>
                                        </ul>
                                      ))
                                    : null}
                                </div>
                              ))
                            ) : (
                              <p className="mt-3 mb-0 text-sm" style={{ fontWeight: "600", color: "#333" }}>
                                Nothing yet...
                              </p>
                            )
                          ) : (
                            <p className="mt-3 mb-0 text-sm" style={{ fontWeight: "600", color: "#333" }}>
                              Nothing yet...
                            </p>
                          )}
                        </CSSTransitionGroup>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          ) : null}
        </>
      ) : null}
    </>
  );
};

export default Event;
