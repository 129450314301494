import axios from "axios";

export default {
  signIn: async (cred) => {
    axios.defaults.headers.common = { Authorization: `Bearer ${cred}` };
    let res = await axios.post(`/api/auth/sign-in`);
    return res.data || [];
  },
  checkAuth: async () => {
    let res = await axios.get(`/api/auth/check-auth`);
    return res.data || [];
  },
  update: async (id, product) => {
    let res = await axios.put(`/api/product/${id}`, product);
    return res.data || [];
  },
  create: async (event) => {
    let res = await axios.post(`/api/event`, event);
    return res.data || [];
  },
  delete: async (id) => {
    let res = await axios.delete(`/api/event/${id}`);
    return res.data || [];
  },
};
