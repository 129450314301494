/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
import SemiCircleProgressBar from "react-progressbar-semicircle";
import eventServices from "../../services/eventServices.js";
import List2 from "../../components/List2";
import classnames from "classnames";
import authServices from "../../services/authServices.js";

import { CSSTransitionGroup } from "react-transition-group";

// reactstrap components
import {
  Button,
  Card,
  CardTitle,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  NavItem,
  NavLink,
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import { motion } from "framer-motion";
import moment from "moment";
import Event from "views/examples/Event";

/*const socket = require("socket.io-client")("http://localhost:3000");*/

const Profile = (props) => {
  const { buttonLabel, className } = props;

  const variants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  const [modal, setModal] = useState(false);
  const [replyModal, setReplyModal] = useState(false);
  const [featuredComment, setFeaturedComment] = useState(null);
  const [dummyState, setDummyState] = useState(null);

  const [activeTab, setActiveTab] = useState("1");

  const [needUpdate, setNeedUpdate] = useState(false);
  const [events, setEvents] = useState(null);
  const [active, setActive] = useState(0);

  useEffect(() => {
    if (!events) {
      getEvents();
    }
  });

  /*const checkAuth = async () => {
    let res = await authServices.checkAuth();
    if (res.success) {
      console.log(res.user);
    }
  };*/

  useEffect(() => {
    setInterval(() => {
      getEvents();
    }, 20000);
  }, []);

  useEffect(() => {
    if (needUpdate) {
      setNeedUpdate(false);
      setTimeout(() => {
        getEvents();
      }, 2000);
    }
  }, [needUpdate]);

  const getEvents = async () => {
    const id = window.location.href.substring(window.location.href.lastIndexOf("/") + 1);
    let res = await eventServices.get(id);
    console.log(res.product);
    setEvents(res.product);
  };

  return (
    <>
      {events ? (
        events.length > 1 ? (
          <div style={{ zIndex: "200", whiteSpace: "nowrap", overflowX: "auto" }}>
            {events.map((event, index) => (
              <div
                style={
                  active === index
                    ? { background: "linear-gradient(87deg, #172b4d 0, #1a174d 100%)", display: "inline-block" }
                    : { backgroundColor: "rgb(26,24,77, .5)", display: "inline-block" }
                }
                onClick={() => setActive(index)}
              >
                <h5
                  style={{
                    display: "inline",
                    color: "white",
                    border: "solid white",
                    padding: "10px 15px",
                    borderRadius: "5px 5px 5px 5px",
                  }}
                >
                  {moment(event.date, "Y-M-D").format("dddd, MMMM D, Y")}
                </h5>
              </div>
            ))}
          </div>
        ) : null
      ) : null}
      {events ? events.map((event, index) => <Event event={event} getEvents={getEvents} index={index} active={active} />) : null}
    </>
  );
};

export default Profile;
