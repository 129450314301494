/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
import axios from "axios";
import { CSSTransitionGroup } from "react-transition-group";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Badge,
  UncontrolledTooltip,
  TabPane,
  Nav,
  CardTitle,
  CardText,
  NavItem,
  NavLink,
  Input,
  Button,
  FormGroup,
  Progress,
  TabContent,
  Label,
} from "reactstrap";
// core components
import UserHeader from "components/Headers/CreateHeader.js";
import Steps from "components/Steps.js";
import eventServices from "../../services/eventServices";
import classnames from "classnames";

const Edit = ({ user: user }) => {
  const [step, setStep] = useState(1);
  const [event, setEvent] = useState("");
  const [creationSuccessful, setCreationSuccessful] = useState(null);
  const [notification, setNotification] = useState("");
  const [modal, setModal] = useState(false);
  const [replyModal, setReplyModal] = useState(false);
  const [featuredComment, setFeaturedComment] = useState(null);
  const [needUpdate, setNeedUpdate] = useState(false);
  const [disable, setDisable] = useState(true);
  const [activeTab, setActiveTab] = useState("1");

  const handleChangeImage = (image) => {
    setEvent({ ...event, image: image });
    setDisable(false);
  };

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const toggleReply = (id) => {
    setReplyModal(!replyModal);
    let res = event.comments.filter((comment) => {
      return comment._id == id;
    });
    setFeaturedComment(res);
  };

  const updateEditEvent = async () => {
    const id = window.location.href.substring(window.location.href.lastIndexOf("/") + 1);
    await eventServices.update(id, event);
    console.log("success");
  };

  useEffect(() => {
    getEvent();
  }, []);

  useEffect(() => {
    if (needUpdate) {
      updateEditEvent();
      setNeedUpdate(false);
      setTimeout(() => {
        getEvent();
      }, 2000);
    }
    setDisable(true);
  }, [needUpdate]);

  const getEvent = async () => {
    const id = window.location.href.substring(window.location.href.lastIndexOf("/") + 1);
    let res = await eventServices.getOne(id);
    console.log(res.product);
    setEvent(res.product);
  };

  /*
  const beforeEventSubmit = (e) => {
    if (user) {
      setEvent({ ...event, ["author"]: user._id });
    }
  };
  */

  const deleteComment = async (id) => {
    console.log(id);
    /*let newArray = event.comments.map((object) => {
      return object._id !== id ? object : null;
    });*/

    let newArray = event.comments.filter((object) => object._id !== id);

    setEvent((event) => ({ ...event, comments: newArray }));
    setNeedUpdate(true);
  };

  const deleteReply = async () => {
    /*let index = featuredComment.map((object) => {
      return event.comments.findIndex((x) => x._id == object._id);
    });
    let middleEvent = event;
    await middleEvent.comments[index].replies.push({ text: message.text, author: message.author });

    setEvent((event) => ({ ...event, comments: middleEvent.comments }));

    setNeedUpdate(true);*/
  };

  const clearLists = () => {
    setEvent({ ...event, yess: null, nos: null });
    setNeedUpdate(true);
  };

  const handleChange = (e) => {
    setEvent({ ...event, [e.target.name]: e.target.value });
    setDisable(false);
  };

  const handleUpdateChange = (e) => {
    setNotification({ ...notification, [e.target.name]: e.target.value });
  };

  const sendUpdate = () => {
    axios.get(`/send-notification?title=${notification.title}&body=${notification.body}&signup=${event.signUpId}`);
  };
  return (
    <>
      <UserHeader />
      {/* Page content */}
      <Container style={{ maxWidth: "1100px" }} className=" mt--7" fluid>
        {/* Table */}
        <Row>
          <div className=" col">
            <Card className=" shadow">
              <CardHeader className=" bg-transparent">
                <CardTitle tag="h5" className="text-uppercase mb-0" style={{ fontWeight: "900", fontColor: "#333" }}>
                  Edit an Event
                  <div className="text-left">
                    <hr style={{ border: "solid 2px #F6343F", width: "4em", margin: "0" }} />
                  </div>
                </CardTitle>
              </CardHeader>
              <CardBody>
                <h4 className="pb-1">Update Notification</h4>

                <FormGroup>
                  <Input
                    onChange={handleUpdateChange}
                    name="title"
                    value={notification.title}
                    type="text"
                    placeholder="Notification Subject"
                  />

                  <Input
                    onChange={handleUpdateChange}
                    name="body"
                    value={notification.body}
                    type="textarea"
                    className="mt-1"
                    placeholder="Quick description of the update."
                  />
                  <Button onClick={sendUpdate} outline block className="mt-3  " color="danger">
                    Send Update
                  </Button>
                </FormGroup>

                <FormGroup>
                  <h4 className=" pb-2 ">Signup List</h4>
                  <Button onClick={() => clearLists} outline block color="danger">
                    Reset Event Signup
                  </Button>
                </FormGroup>

                <h4>Event Status</h4>

                <FormGroup check>
                  <Label check>
                    <Input
                      onChange={handleChange}
                      checked={event ? (event.status === "Live" ? true : false) : false}
                      value="Live"
                      className="text-muted"
                      type="radio"
                      name="status"
                    />
                    Live
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      onChange={handleChange}
                      checked={event ? (event.status === "Offline" ? true : false) : false}
                      value="Offline"
                      name="status"
                      className="text-muted"
                      type="radio"
                    />
                    Offline
                  </Label>
                </FormGroup>

                <Nav className="mt-5" style={{ whiteSpace: "nowrap", overflowX: "auto", overflowY: "hidden", flexWrap: "nowrap" }} tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "1" })}
                      onClick={() => {
                        toggle("1");
                      }}
                    >
                      Overview
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "2" })}
                      onClick={() => {
                        toggle("2");
                      }}
                    >
                      Location & Time
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "3" })}
                      onClick={() => {
                        toggle("3");
                      }}
                    >
                      Display
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "4" })}
                      onClick={() => {
                        toggle("4");
                      }}
                    >
                      Comments
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent style={{ minHeight: "400px" }} activeTab={activeTab}>
                  <TabPane tabId="1">
                    <Row>
                      <Col sm="12">
                        <FormGroup>
                          <h4 className=" mt-3 pb-1">Event Name</h4>
                          <Input
                            onChange={handleChange}
                            name="name"
                            value={event.name}
                            type="text"
                            placeholder="Ex: Ugly Sweater Christmas Party"
                          />
                        </FormGroup>
                        <FormGroup>
                          <h4 className=" pb-2 ">Event Description</h4>
                          <Input
                            onChange={handleChange}
                            className="description"
                            name="description"
                            value={event.description}
                            type="textarea"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="2">
                    <Row>
                      <Col sm="12">
                        <h4 className=" mt-3 pb-1">Event Location Name</h4>
                        <FormGroup>
                          <Input
                            onChange={handleChange}
                            name="nameOfLocation"
                            value={event.nameOfLocation}
                            type="text"
                            placeholder="Ex: Ugly Sweater Christmas Party"
                          />
                        </FormGroup>
                        <h4 className=" pb-1">Event Address</h4>
                        <FormGroup>
                          <Input
                            onChange={handleChange}
                            name="address"
                            value={event.address}
                            type="textarea"
                            placeholder="Ex: Ugly Sweater Christmas Party"
                          />
                        </FormGroup>
                        <FormGroup>
                          <h4 className="mt-3 pb-2 ">Event Date</h4>
                          <Input
                            onChange={handleChange}
                            value={event.date}
                            name="date"
                            type="date"
                            name="date"
                            id="exampleDate"
                            placeholder="date placeholder"
                          />
                        </FormGroup>
                        <FormGroup>
                          <h4 className=" pb-2">Event Time</h4>
                          <Input
                            onChange={handleChange}
                            name="time"
                            value={event.time}
                            type="time"
                            name="time"
                            id="exampleTime"
                            placeholder="time placeholder"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="3">
                    <Row>
                      <Col sm="12">
                        <div>
                          <h4 className="pb-2 pt-3">Event Background Image</h4>
                          <Container fluid>
                            {/* Table */}

                            {event.type === "Sports" ? (
                              <Row>
                                <Col xs="6" sm="6" lg="6" xl="6">
                                  <Card
                                    onClick={() => {
                                      handleChangeImage("https://res.cloudinary.com/dmcuruoxf/image/upload/v1609967402/sports2_hxlqsf.jpg");
                                    }}
                                    className="shadow"
                                  >
                                    <div
                                      className={
                                        event.image === "https://res.cloudinary.com/dmcuruoxf/image/upload/v1609967402/sports2_hxlqsf.jpg"
                                          ? "active-image"
                                          : null
                                      }
                                      style={{ boxShadow: "1px 2px 3px #333", borderRadius: "10px" }}
                                    >
                                      <img
                                        style={{ width: "100%", height: "100px", objectFit: "cover", borderRadius: "10px" }}
                                        src={"https://res.cloudinary.com/dmcuruoxf/image/upload/v1609967402/sports2_hxlqsf.jpg"}
                                      />
                                    </div>
                                  </Card>
                                </Col>
                                <Col xs="6" sm="6" lg="6" xl="6">
                                  <Card
                                    onClick={() => {
                                      handleChangeImage("https://res.cloudinary.com/dmcuruoxf/image/upload/v1609967403/sports_epsoy8.jpg");
                                    }}
                                    className="shadow"
                                  >
                                    <div
                                      className={
                                        event.image === "https://res.cloudinary.com/dmcuruoxf/image/upload/v1609967403/sports_epsoy8.jpg"
                                          ? "active-image"
                                          : null
                                      }
                                      style={{ boxShadow: "1px 2px 3px #333", borderRadius: "10px" }}
                                    >
                                      <img
                                        style={{ width: "100%", height: "100px", objectFit: "cover", borderRadius: "10px" }}
                                        src={"https://res.cloudinary.com/dmcuruoxf/image/upload/v1609967403/sports_epsoy8.jpg"}
                                      />
                                    </div>
                                  </Card>
                                </Col>{" "}
                              </Row>
                            ) : event.type === "Party" ? (
                              <Row>
                                <Col xs="6" sm="6" lg="6" xl="6">
                                  <Card
                                    onClick={() => {
                                      handleChangeImage("https://res.cloudinary.com/dmcuruoxf/image/upload/v1610378690/party_svbwje.jpg");
                                    }}
                                    className="shadow"
                                  >
                                    <div
                                      className={
                                        event.image === "https://res.cloudinary.com/dmcuruoxf/image/upload/v1610378690/party_svbwje.jpg"
                                          ? "active-image"
                                          : null
                                      }
                                      style={{ boxShadow: "1px 2px 3px #333", borderRadius: "10px" }}
                                    >
                                      <img
                                        style={{ width: "100%", height: "100px", objectFit: "cover", borderRadius: "10px" }}
                                        src={"https://res.cloudinary.com/dmcuruoxf/image/upload/v1610378690/party_svbwje.jpg"}
                                      />
                                    </div>
                                  </Card>
                                </Col>
                                <Col xs="6" sm="6" lg="6" xl="6">
                                  <Card
                                    onClick={() => {
                                      handleChangeImage("https://res.cloudinary.com/dmcuruoxf/image/upload/v1610378690/party3_bqzdlb.jpg");
                                    }}
                                    className="shadow"
                                  >
                                    <div
                                      className={
                                        event.image === "https://res.cloudinary.com/dmcuruoxf/image/upload/v1610378690/party3_bqzdlb.jpg"
                                          ? "active-image"
                                          : null
                                      }
                                      style={{ boxShadow: "1px 2px 3px #333", borderRadius: "10px" }}
                                    >
                                      <img
                                        style={{ width: "100%", height: "100px", objectFit: "cover", borderRadius: "10px" }}
                                        src={"https://res.cloudinary.com/dmcuruoxf/image/upload/v1610378690/party3_bqzdlb.jpg"}
                                      />
                                    </div>
                                  </Card>
                                </Col>
                                <Col className="mt-3" xs="6" sm="6" lg="6" xl="6">
                                  <Card
                                    onClick={() => {
                                      handleChangeImage("https://res.cloudinary.com/dmcuruoxf/image/upload/v1610378691/party2_fslx9a.jpg");
                                    }}
                                    className="shadow"
                                  >
                                    <div
                                      className={
                                        event.image === "https://res.cloudinary.com/dmcuruoxf/image/upload/v1610378691/party2_fslx9a.jpg"
                                          ? "active-image"
                                          : null
                                      }
                                      style={{ boxShadow: "1px 2px 3px #333", borderRadius: "10px" }}
                                    >
                                      <img
                                        style={{ width: "100%", height: "100px", objectFit: "cover", borderRadius: "10px" }}
                                        src={"https://res.cloudinary.com/dmcuruoxf/image/upload/v1610378691/party2_fslx9a.jpg"}
                                      />
                                    </div>
                                  </Card>
                                </Col>
                              </Row>
                            ) : event.type === "Volunteer" ? (
                              <Row>
                                <Col xs="6" sm="6" lg="6" xl="6">
                                  <Card
                                    onClick={() => {
                                      handleChangeImage(
                                        "https://res.cloudinary.com/dmcuruoxf/image/upload/v1610378690/volunteer1_k3uohv.jpg"
                                      );
                                    }}
                                    className="shadow"
                                  >
                                    <div
                                      className={
                                        event.image ===
                                        "https://res.cloudinary.com/dmcuruoxf/image/upload/v1610378690/volunteer1_k3uohv.jpg"
                                          ? "active-image"
                                          : null
                                      }
                                      style={{ boxShadow: "1px 2px 3px #333", borderRadius: "10px" }}
                                    >
                                      <img
                                        style={{ width: "100%", height: "100px", objectFit: "cover", borderRadius: "10px" }}
                                        src={"https://res.cloudinary.com/dmcuruoxf/image/upload/v1610378690/volunteer1_k3uohv.jpg"}
                                      />
                                    </div>
                                  </Card>
                                </Col>
                                <Col xs="6" sm="6" lg="6" xl="6">
                                  <Card
                                    onClick={() => {
                                      handleChangeImage(
                                        "https://res.cloudinary.com/dmcuruoxf/image/upload/v1610378691/volunteer_ppmdvd.jpgg"
                                      );
                                    }}
                                    className="shadow"
                                  >
                                    <div
                                      className={
                                        event.image ===
                                        "https://res.cloudinary.com/dmcuruoxf/image/upload/v1610378691/volunteer_ppmdvd.jpgg"
                                          ? "active-image"
                                          : null
                                      }
                                      style={{ boxShadow: "1px 2px 3px #333", borderRadius: "10px" }}
                                    >
                                      <img
                                        style={{ width: "100%", height: "100px", objectFit: "cover", borderRadius: "10px" }}
                                        src={"https://res.cloudinary.com/dmcuruoxf/image/upload/v1610378691/volunteer_ppmdvd.jpg"}
                                      />
                                    </div>
                                  </Card>
                                </Col>
                              </Row>
                            ) : null}
                          </Container>
                        </div>
                      </Col>
                    </Row>
                  </TabPane>

                  <TabPane tabId="4">
                    <Row>
                      <Col sm="12">
                        <div>
                          <h4 className="pb-2 pt-3">Signup Comments</h4>
                          <CSSTransitionGroup transitionName="example" transitionEnterTimeout={500} transitionLeaveTimeout={300}>
                            {event ? (
                              event.comments.length > 0 ? (
                                event.comments.map((object, index) => (
                                  <div>
                                    <p id={index} className="mt-3 mb-0 text-sm comment">
                                      {object.text}
                                      <br />
                                      <span style={{ color: "lightgrey" }}>
                                        {object.author} &nbsp;{" "}
                                        <Badge color="danger" onClick={() => deleteComment(object._id)}>
                                          Delete
                                        </Badge>
                                      </span>
                                    </p>
                                    {object.replies.length > 0
                                      ? object.replies.map((o, index) => (
                                          <ul>
                                            <p id={index} className="mt-3 mb-0 text-sm comment">
                                              {o.text}
                                              <br />
                                              <span style={{ color: "lightgrey" }}>{o.author}</span>
                                            </p>
                                          </ul>
                                        ))
                                      : null}
                                  </div>
                                ))
                              ) : (
                                <p className="mt-3 mb-0 text-sm">Nothing yet.</p>
                              )
                            ) : (
                              <p className="mt-3 mb-0 text-sm">Nothing yet.</p>
                            )}
                          </CSSTransitionGroup>
                        </div>
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>

            <div
              style={{
                backgroundColor: "white",
                display: "block",
                position: "fixed",
                padding: "10px 3px",
                bottom: "0px",
                zIndex: "1",
                width: "100%",
                left: "0%",
                boxShadow: "0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)",
              }}
            >
              <div style={{ width: "50%", display: "inline-block", textAlign: "left" }}>
                <Button href="/admin">Back</Button>
              </div>
              <div style={{ width: "50%", display: "inline-block", textAlign: "right" }}>
                <Button disabled={disable} color="success" onClick={() => setNeedUpdate(true)} block>
                  Save
                </Button>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Edit;
