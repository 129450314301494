/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components
import AdminNavbar from "components/Sidebar/PublicSidebar.js";
import AuthFooter from "components/Footers/AuthFooter.js";

import routes from "routes.js";

class Auth extends React.Component {
  componentDidMount() {
    document.body.classList.add("bg-default");
  }
  componentWillUnmount() {
    document.body.classList.remove("bg-default");
  }
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
      } else {
        return null;
      }
    });
  };
  render() {
    return (
      <>
        <div className="main-content">
          <div style={{ height: "100vh" }} className="header bg-gradient-info py-5 py-lg-8">
            <Container>
              <div className="header-body text-center mb-7">
                <Row className="justify-content-center">
                  <Col lg="5" md="6">
                    <img src={require("assets/img/brand/logo-hub1.png")} alt="" style={{ width: "60%" }} />
                    {/*<p className="text-white" style={{ fontWeight: "600" }}>
                      Create your Signup
    </p>*/}
                  </Col>
                </Row>
              </div>
              <Container className=" pb-5" style={{ marginTop: "250px" }}>
                <Row className="justify-content-center">
                  <Switch>
                    {this.getRoutes(routes)}
                    <Redirect from="*" to="/auth/login" />
                  </Switch>
                </Row>
              </Container>
            </Container>
            {/*<div className="separator separator-bottom separator-skew zindex-100">
              <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" version="1.1" viewBox="0 0 2560 100" x="0" y="0">
                <polygon className="fill-default" points="2560 0 2560 100 0 100" />
              </svg>
    </div>*/}
          </div>
          {/* Page content */}
        </div>
        <AuthFooter />
      </>
    );
  }
}

export default Auth;
