/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Button, Container, Row, Col, Spinner } from "reactstrap";
import moment from "moment";

class UserHeader extends React.Component {
  render() {
    const { event } = this.props;

    return (
      <>
        {event ? (
          <div
            className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
            style={
              event.image
                ? {
                    minHeight: "600px",
                    zIndex: "0",
                    backgroundImage: `url( ${event.image})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center top",
                  }
                : {
                    minHeight: "600px",
                    zIndex: "0",
                    backgroundImage: "url(" + require("assets/img/theme/sports.jpg") + ")",
                    backgroundSize: "cover",
                    backgroundPosition: "center top",
                  }
            }
          >
            {/* Mask */}
            <span className="mask bg-gradient-default" style={{ opacity: ".77" }} />
            {/* Header container */}
            <Container className="d-flex align-items-center" fluid>
              <Row>
                <Col lg="7" md="10">
                  <h3 className="display-3 text-white">{event.name}</h3>
                  <p className="text-white mt-0 mb-3">{event.description}</p>
                  <p className="text-white mt-0 mb-1">
                    <strong>
                      <i className="ni ni-calendar-grid-58" />
                      &nbsp;{moment(event.date, "Y-M-D").format("dddd, MMMM D, Y")}
                    </strong>
                  </p>
                  <p className="text-white mt-0 mb-1">
                    <strong>
                      <i className="ni ni-watch-time  " />
                      &nbsp;{moment(event.time, "hh:mm").format("LT")}
                      {event.timeEnd ? ` - ${event.timeEnd}` : null}
                    </strong>
                  </p>
                  <p className="text-white mt-0 mb-3">
                    <strong>
                      <i className="ni ni-pin-3  " />
                      &nbsp;{event.nameOfLocation}
                    </strong>
                  </p>
                  <Button color="danger" style={{ backgroundColor: "#E80E0E" }} href={`http://maps.google.com/?q=${event.address}`}>
                    Get Directions
                  </Button>
                </Col>
              </Row>
            </Container>
          </div>
        ) : (
          <div style={{ height: "400px", position: "relative" }}>
            <Spinner color="danger" style={{ width: "3rem", height: "3rem", position: "absolute", top: "50%", left: "40%" }} />
          </div>
        )}
      </>
    );
  }
}

export default UserHeader;
