/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  Col,
  CardBody,
  CardTitle,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import eventServices from "../../services/eventServices.js";
import { mapToCssModules } from "reactstrap/lib/utils";

const Tables = (props) => {
  const [events, setEvents] = useState(null);
  const getEvents = async () => {
    console.log(props.user._id);
    let user = props.user._id;
    let res = await eventServices.getAll(user);
    console.log(res);
    setEvents(res);
  };

  useEffect(() => {
    getEvents(props.user._id);
  }, []);

  const deleteEvent = async (id) => {
    let res = await eventServices.delete(id);
    console.log(res);
    getEvents();
  };

  return (
    <>
      <Header user={props.user} />
      {/* Page content */}
      <Container style={{ maxWidth: "1200px" }} className=" mt--5 " fluid>
        {/* Table */}
        <Row>
          <Col lg="6" xl="6" className="center mb-3">
            <Card className="card-stats mb-4 mb-xl-0 shadow">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle tag="h5" className="text-uppercase mb-0" style={{ fontWeight: "900", fontColor: "#333" }}>
                      Creation Center
                      <div className="text-left">
                        <hr style={{ border: "solid 2px #F6343F", width: "4em", margin: "0" }} />
                      </div>
                    </CardTitle>

                    <p className="text-muted pt-1 text-sm"></p>
                    <Button href="/admin/create" block size="lg" color="danger" outline>
                      Create Signup
                    </Button>
                  </div>
                  {/*<Col className="col-auto">
                    <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                      <i className="fas fa-plus" />
                    </div>
  </Col>*/}
                </Row>
              </CardBody>
            </Card>
          </Col>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h5 className="mb-0 text-uppercase" style={{ fontWeight: "900", fontColor: "#333" }}>
                  Your events
                  <div className="text-left">
                    <hr style={{ border: "solid 2px #F6343F", width: "4em", margin: "0" }} />
                  </div>
                </h5>
              </CardHeader>
              <p style={{ lineHeight: "1px", fontSize: "12px", marginRight: "4px" }} className="text-right text-light">
                Swipe &#x2192;
              </p>
              <Table className="align-items-center table-flush" style={{ marginBottom: "50px" }} responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Actions</th>
                    <th scope="col">Name</th>
                    <th scope="col">Date</th>
                    <th scope="col">Going</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {events
                    ? events.length > 0
                      ? events.map((event) => (
                          <tr>
                            <td className="text-center">
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  className="btn-icon-only text-light"
                                  href="#pablo"
                                  role="button"
                                  size="sm"
                                  color=""
                                  onClick={(e) => e.preventDefault()}
                                >
                                  <i className="fas fa-ellipsis-v" />
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-arrow" right>
                                  <DropdownItem href="#pablo" href={"/hub/event/" + event.signUpId}>
                                    View Event
                                  </DropdownItem>
                                  <DropdownItem href={`/admin/edit/${event._id}`}>Edit Event</DropdownItem>
                                  <DropdownItem onClick={(e) => deleteEvent(event._id)}>Delete Event</DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                            <th scope="row">
                              <Media className="align-items-center">
                                {/*<a className="avatar rounded-circle mr-3" href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img alt="..." src={require("assets/img/theme/bootstrap.jpg")} />
                        </a>*/}
                                <Media>
                                  <span className="mb-0 text-sm">{event.name}</span>
                                </Media>
                              </Media>
                            </th>
                            <td>{event.date}</td>
                            <td>{event.yess.length}</td>
                            <td>
                              {event.status === "Live" ? (
                                <span style={{ fontWeight: "900", color: "green" }}>{event.status}</span>
                              ) : (
                                <span style={{ fontWeight: "900", color: "darkyellow" }}>{event.status}</span>
                              )}
                            </td>
                          </tr>
                        ))
                      : null
                    : null}
                </tbody>
              </Table>

              {/*<CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination className="pagination justify-content-end mb-0" listClassName="justify-content-end mb-0">
                    <PaginationItem className="disabled">
                      <PaginationLink href="#pablo" onClick={(e) => e.preventDefault()} tabIndex="-1">
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem className="active">
                      <PaginationLink href="#pablo" onClick={(e) => e.preventDefault()}>
                        1
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink href="#pablo" onClick={(e) => e.preventDefault()}>
                        2 <span className="sr-only">(current)</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink href="#pablo" onClick={(e) => e.preventDefault()}>
                        3
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink href="#pablo" onClick={(e) => e.preventDefault()}>
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
                  </CardFooter>*/}
              {events ? (
                events.length === 0 ? (
                  <h5 style={{ textAlign: "center", marginTop: "-40px", paddingBottom: "30px" }} className="text-light">
                    Your events will be listed here.
                  </h5>
                ) : null
              ) : null}
            </Card>
          </div>
        </Row>
        {/* Dark table */}
        {/*<Row className="mt-5">
            <div className="col">
              <Card className="bg-default shadow">
                <CardHeader className="bg-transparent border-0">
                  <h3 className="text-white mb-0">Card tables</h3>
                </CardHeader>
                <Table className="align-items-center table-dark table-flush" responsive>
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">Project</th>
                      <th scope="col">Budget</th>
                      <th scope="col">Status</th>
                      <th scope="col">Users</th>
                      <th scope="col">Completion</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">
                        <Media className="align-items-center">
                          <a className="avatar rounded-circle mr-3" href="#pablo" onClick={(e) => e.preventDefault()}>
                            <img alt="..." src={require("assets/img/theme/bootstrap.jpg")} />
                          </a>
                          <Media>
                            <span className="mb-0 text-sm">Argon Design System</span>
                          </Media>
                        </Media>
                      </th>
                      <td>$2,500 USD</td>
                      <td>
                        <Badge color="" className="badge-dot mr-4">
                          <i className="bg-warning" />
                          pending
                        </Badge>
                      </td>
                      <td>
                        <div className="avatar-group">
                          <a className="avatar avatar-sm" href="#pablo" id="tooltip731399078" onClick={(e) => e.preventDefault()}>
                            <img alt="..." className="rounded-circle" src={require("assets/img/theme/team-1-800x800.jpg")} />
                          </a>
                          <UncontrolledTooltip delay={0} target="tooltip731399078">
                            Ryan Tompson
                          </UncontrolledTooltip>
                          <a className="avatar avatar-sm" href="#pablo" id="tooltip491083084" onClick={(e) => e.preventDefault()}>
                            <img alt="..." className="rounded-circle" src={require("assets/img/theme/team-2-800x800.jpg")} />
                          </a>
                          <UncontrolledTooltip delay={0} target="tooltip491083084">
                            Romina Hadid
                          </UncontrolledTooltip>
                          <a className="avatar avatar-sm" href="#pablo" id="tooltip528540780" onClick={(e) => e.preventDefault()}>
                            <img alt="..." className="rounded-circle" src={require("assets/img/theme/team-3-800x800.jpg")} />
                          </a>
                          <UncontrolledTooltip delay={0} target="tooltip528540780">
                            Alexander Smith
                          </UncontrolledTooltip>
                          <a className="avatar avatar-sm" href="#pablo" id="tooltip237898869" onClick={(e) => e.preventDefault()}>
                            <img alt="..." className="rounded-circle" src={require("assets/img/theme/team-4-800x800.jpg")} />
                          </a>
                          <UncontrolledTooltip delay={0} target="tooltip237898869">
                            Jessica Doe
                          </UncontrolledTooltip>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">60%</span>
                          <div>
                            <Progress max="100" value="60" barClassName="bg-warning" />
                          </div>
                        </div>
                      </td>
                      <td className="text-right">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            href="#pablo"
                            role="button"
                            size="sm"
                            color=""
                            onClick={(e) => e.preventDefault()}
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right>
                            <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
                              Action
                            </DropdownItem>
                            <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
                              Another action
                            </DropdownItem>
                            <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
                              Something else here
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <Media className="align-items-center">
                          <a className="avatar rounded-circle mr-3" href="#pablo" onClick={(e) => e.preventDefault()}>
                            <img alt="..." src={require("assets/img/theme/angular.jpg")} />
                          </a>
                          <Media>
                            <span className="mb-0 text-sm">Angular Now UI Kit PRO</span>
                          </Media>
                        </Media>
                      </th>
                      <td>$1,800 USD</td>
                      <td>
                        <Badge color="" className="badge-dot">
                          <i className="bg-success" />
                          completed
                        </Badge>
                      </td>
                      <td>
                        <div className="avatar-group">
                          <a className="avatar avatar-sm" href="#pablo" id="tooltip188614932" onClick={(e) => e.preventDefault()}>
                            <img alt="..." className="rounded-circle" src={require("assets/img/theme/team-1-800x800.jpg")} />
                          </a>
                          <UncontrolledTooltip delay={0} target="tooltip188614932">
                            Ryan Tompson
                          </UncontrolledTooltip>
                          <a className="avatar avatar-sm" href="#pablo" id="tooltip66535734" onClick={(e) => e.preventDefault()}>
                            <img alt="..." className="rounded-circle" src={require("assets/img/theme/team-2-800x800.jpg")} />
                          </a>
                          <UncontrolledTooltip delay={0} target="tooltip66535734">
                            Romina Hadid
                          </UncontrolledTooltip>
                          <a className="avatar avatar-sm" href="#pablo" id="tooltip427561578" onClick={(e) => e.preventDefault()}>
                            <img alt="..." className="rounded-circle" src={require("assets/img/theme/team-3-800x800.jpg")} />
                          </a>
                          <UncontrolledTooltip delay={0} target="tooltip427561578">
                            Alexander Smith
                          </UncontrolledTooltip>
                          <a className="avatar avatar-sm" href="#pablo" id="tooltip904098289" onClick={(e) => e.preventDefault()}>
                            <img alt="..." className="rounded-circle" src={require("assets/img/theme/team-4-800x800.jpg")} />
                          </a>
                          <UncontrolledTooltip delay={0} target="tooltip904098289">
                            Jessica Doe
                          </UncontrolledTooltip>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">100%</span>
                          <div>
                            <Progress max="100" value="100" barClassName="bg-success" />
                          </div>
                        </div>
                      </td>
                      <td className="text-right">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            href="#pablo"
                            role="button"
                            size="sm"
                            color=""
                            onClick={(e) => e.preventDefault()}
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right>
                            <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
                              Action
                            </DropdownItem>
                            <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
                              Another action
                            </DropdownItem>
                            <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
                              Something else here
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <Media className="align-items-center">
                          <a className="avatar rounded-circle mr-3" href="#pablo" onClick={(e) => e.preventDefault()}>
                            <img alt="..." src={require("assets/img/theme/sketch.jpg")} />
                          </a>
                          <Media>
                            <span className="mb-0 text-sm">Black Dashboard</span>
                          </Media>
                        </Media>
                      </th>
                      <td>$3,150 USD</td>
                      <td>
                        <Badge color="" className="badge-dot mr-4">
                          <i className="bg-danger" />
                          delayed
                        </Badge>
                      </td>
                      <td>
                        <div className="avatar-group">
                          <a className="avatar avatar-sm" href="#pablo" id="tooltip707904950" onClick={(e) => e.preventDefault()}>
                            <img alt="..." className="rounded-circle" src={require("assets/img/theme/team-1-800x800.jpg")} />
                          </a>
                          <UncontrolledTooltip delay={0} target="tooltip707904950">
                            Ryan Tompson
                          </UncontrolledTooltip>
                          <a className="avatar avatar-sm" href="#pablo" id="tooltip353988222" onClick={(e) => e.preventDefault()}>
                            <img alt="..." className="rounded-circle" src={require("assets/img/theme/team-2-800x800.jpg")} />
                          </a>
                          <UncontrolledTooltip delay={0} target="tooltip353988222">
                            Romina Hadid
                          </UncontrolledTooltip>
                          <a className="avatar avatar-sm" href="#pablo" id="tooltip467171202" onClick={(e) => e.preventDefault()}>
                            <img alt="..." className="rounded-circle" src={require("assets/img/theme/team-3-800x800.jpg")} />
                          </a>
                          <UncontrolledTooltip delay={0} target="tooltip467171202">
                            Alexander Smith
                          </UncontrolledTooltip>
                          <a className="avatar avatar-sm" href="#pablo" id="tooltip362118155" onClick={(e) => e.preventDefault()}>
                            <img alt="..." className="rounded-circle" src={require("assets/img/theme/team-4-800x800.jpg")} />
                          </a>
                          <UncontrolledTooltip delay={0} target="tooltip362118155">
                            Jessica Doe
                          </UncontrolledTooltip>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">72%</span>
                          <div>
                            <Progress max="100" value="72" barClassName="bg-danger" />
                          </div>
                        </div>
                      </td>
                      <td className="text-right">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            href="#pablo"
                            role="button"
                            size="sm"
                            color=""
                            onClick={(e) => e.preventDefault()}
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right>
                            <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
                              Action
                            </DropdownItem>
                            <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
                              Another action
                            </DropdownItem>
                            <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
                              Something else here
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <Media className="align-items-center">
                          <a className="avatar rounded-circle mr-3" href="#pablo" onClick={(e) => e.preventDefault()}>
                            <img alt="..." src={require("assets/img/theme/react.jpg")} />
                          </a>
                          <Media>
                            <span className="mb-0 text-sm">React Material Dashboard</span>
                          </Media>
                        </Media>
                      </th>
                      <td>$4,400 USD</td>
                      <td>
                        <Badge color="" className="badge-dot">
                          <i className="bg-info" />
                          on schedule
                        </Badge>
                      </td>
                      <td>
                        <div className="avatar-group">
                          <a className="avatar avatar-sm" href="#pablo" id="tooltip226319315" onClick={(e) => e.preventDefault()}>
                            <img alt="..." className="rounded-circle" src={require("assets/img/theme/team-1-800x800.jpg")} />
                          </a>
                          <UncontrolledTooltip delay={0} target="tooltip226319315">
                            Ryan Tompson
                          </UncontrolledTooltip>
                          <a className="avatar avatar-sm" href="#pablo" id="tooltip711961370" onClick={(e) => e.preventDefault()}>
                            <img alt="..." className="rounded-circle" src={require("assets/img/theme/team-2-800x800.jpg")} />
                          </a>
                          <UncontrolledTooltip delay={0} target="tooltip711961370">
                            Romina Hadid
                          </UncontrolledTooltip>
                          <a className="avatar avatar-sm" href="#pablo" id="tooltip216246707" onClick={(e) => e.preventDefault()}>
                            <img alt="..." className="rounded-circle" src={require("assets/img/theme/team-3-800x800.jpg")} />
                          </a>
                          <UncontrolledTooltip delay={0} target="tooltip216246707">
                            Alexander Smith
                          </UncontrolledTooltip>
                          <a className="avatar avatar-sm" href="#pablo" id="tooltip638048561" onClick={(e) => e.preventDefault()}>
                            <img alt="..." className="rounded-circle" src={require("assets/img/theme/team-4-800x800.jpg")} />
                          </a>
                          <UncontrolledTooltip delay={0} target="tooltip638048561">
                            Jessica Doe
                          </UncontrolledTooltip>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">90%</span>
                          <div>
                            <Progress max="100" value="90" barClassName="bg-info" />
                          </div>
                        </div>
                      </td>
                      <td className="text-right">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            href="#pablo"
                            role="button"
                            size="sm"
                            color=""
                            onClick={(e) => e.preventDefault()}
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right>
                            <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
                              Action
                            </DropdownItem>
                            <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
                              Another action
                            </DropdownItem>
                            <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
                              Something else here
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <Media className="align-items-center">
                          <a className="avatar rounded-circle mr-3" href="#pablo" onClick={(e) => e.preventDefault()}>
                            <img alt="..." src={require("assets/img/theme/vue.jpg")} />
                          </a>
                          <Media>
                            <span className="mb-0 text-sm">Vue Paper UI Kit PRO</span>
                          </Media>
                        </Media>
                      </th>
                      <td>$2,200 USD</td>
                      <td>
                        <Badge color="" className="badge-dot mr-4">
                          <i className="bg-success" />
                          completed
                        </Badge>
                      </td>
                      <td>
                        <div className="avatar-group">
                          <a className="avatar avatar-sm" href="#pablo" id="tooltip781594051" onClick={(e) => e.preventDefault()}>
                            <img alt="..." className="rounded-circle" src={require("assets/img/theme/team-1-800x800.jpg")} />
                          </a>
                          <UncontrolledTooltip delay={0} target="tooltip781594051">
                            Ryan Tompson
                          </UncontrolledTooltip>
                          <a className="avatar avatar-sm" href="#pablo" id="tooltip840372212" onClick={(e) => e.preventDefault()}>
                            <img alt="..." className="rounded-circle" src={require("assets/img/theme/team-2-800x800.jpg")} />
                          </a>
                          <UncontrolledTooltip delay={0} target="tooltip840372212">
                            Romina Hadid
                          </UncontrolledTooltip>
                          <a className="avatar avatar-sm" href="#pablo" id="tooltip497647175" onClick={(e) => e.preventDefault()}>
                            <img alt="..." className="rounded-circle" src={require("assets/img/theme/team-3-800x800.jpg")} />
                          </a>
                          <UncontrolledTooltip delay={0} target="tooltip497647175">
                            Alexander Smith
                          </UncontrolledTooltip>
                          <a className="avatar avatar-sm" href="#pablo" id="tooltip951447946" onClick={(e) => e.preventDefault()}>
                            <img alt="..." className="rounded-circle" src={require("assets/img/theme/team-4-800x800.jpg")} />
                          </a>
                          <UncontrolledTooltip delay={0} target="tooltip951447946">
                            Jessica Doe
                          </UncontrolledTooltip>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">100%</span>
                          <div>
                            <Progress max="100" value="100" barClassName="bg-danger" />
                          </div>
                        </div>
                      </td>
                      <td className="text-right">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            href="#pablo"
                            role="button"
                            size="sm"
                            color=""
                            onClick={(e) => e.preventDefault()}
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right>
                            <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
                              Action
                            </DropdownItem>
                            <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
                              Another action
                            </DropdownItem>
                            <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
                              Something else here
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card>
            </div>
    </Row>*/}
      </Container>
    </>
  );
};

export default Tables;
