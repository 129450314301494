/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
// reactstrap components
import { Card, CardHeader, CardTitle, CardBody, Container, Row, Col, UncontrolledTooltip, Input, Button, Progress } from "reactstrap";
// core components
import UserHeader from "components/Headers/CreateHeader.js";
import Steps from "components/Steps.js";
import eventServices from "../../services/eventServices";
import { nanoid } from "nanoid";

const Create = ({ user: user }) => {
  const [step, setStep] = useState(1);
  const [event, setEvent] = useState("");
  const [newEvent, setNewEvent] = useState("");
  const [creationSuccessful, setCreationSuccessful] = useState(null);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    beforeEventSubmit();
  }, []);

  const CreateEvent = () => {
    createEvent();
  };

  const beforeEventSubmit = (e) => {
    let id = nanoid(11);
    if (user) {
      setEvent({ ...event, ["author"]: user._id, ["status"]: "Live", ["signUpId"]: id });
    }
  };

  const createEvent = async (e) => {
    let res = await eventServices.create(event);
    if (!res.error) {
      setEvent(res.event);
      setCreationSuccessful(true);
      setStep(step + 1);
    }
  };

  return (
    <>
      <UserHeader />
      {/* Page content */}
      <Container className=" mt--7" fluid>
        {/* Table */}
        <Row>
          <div className=" col">
            <Card className=" shadow">
              <CardHeader className=" bg-transparent">
                {step !== 7 ? (
                  <CardTitle tag="h5" className="text-uppercase mb-0" style={{ fontWeight: "900", fontColor: "#333" }}>
                    Create Event
                    <div className="text-left">
                      <hr style={{ border: "solid 2px #F6343F", width: "4em", margin: "0" }} />
                    </div>
                  </CardTitle>
                ) : (
                  <CardTitle tag="h5" className="text-uppercase mb-0" style={{ fontWeight: "900", fontColor: "#333" }}>
                    Event Created
                    <div className="text-left">
                      <hr style={{ border: "solid 2px #F6343F", width: "4em", margin: "0" }} />
                    </div>
                  </CardTitle>
                )}
              </CardHeader>

              <CardBody>
                <Progress animated color="success" value={step * 15} />
                <div style={{ minHeight: "400px" }}>
                  <Steps setDisabled={setDisabled} setEvent={setEvent} setStep={setStep} event={event} step={step} />
                </div>
              </CardBody>
            </Card>
            {step !== 7 ? (
              <div
                style={{
                  backgroundColor: "white",
                  display: "block",
                  position: "fixed",
                  padding: "10px 3px",
                  bottom: "0px",
                  zIndex: "1",
                  width: "100%",
                  left: "0%",
                  boxShadow: "0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)",
                }}
              >
                <div style={{ width: "50%", display: "inline-block" }}>
                  {step !== 1 ? (
                    <Button
                      onClick={(e) => {
                        setStep(step - 1);
                      }}
                    >
                      Back
                    </Button>
                  ) : (
                    <Button href="/admin/index">Back</Button>
                  )}
                </div>
                <div style={{ width: "50%", display: "inline-block", textAlign: "right" }}>
                  {step !== 6 ? (
                    <Button
                      color="success"
                      onClick={(e) => {
                        setStep(step + 1);
                      }}
                      disabled={!disabled}
                    >
                      Next
                    </Button>
                  ) : (
                    <Button
                      color="success"
                      onClick={(e) => {
                        CreateEvent(e);
                      }}
                    >
                      Create Event
                    </Button>
                  )}
                </div>
              </div>
            ) : null}
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Create;
