import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Input,
  Button,
  Progress,
  FormGroup,
  Label,
} from "reactstrap";
import { motion } from "framer-motion";
import Date from "components/Date";

import "react-day-picker/lib/style.css";

const Steps = (props) => {
  const { step, setStep, event, setEvent } = props;
  const [active, setActive] = useState(false);
  const [activeImage, setActiveImage] = useState(false);

  const variants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  const history = useHistory();

  useEffect(() => {
    console.log(event.name);
    if (step === 1) {
      if (event.type) {
        props.setDisabled(true);
      } else {
        props.setDisabled(false);
      }
    } else if (step === 2) {
      if (event.image) {
        props.setDisabled(true);
      } else {
        props.setDisabled(false);
      }
    } else if (step === 3) {
      if (event.name) {
        props.setDisabled(true);
      } else {
        props.setDisabled(false);
      }
    } else if (step === 4) {
      if (event.nameOfLocation && event.address) {
        props.setDisabled(true);
      } else {
        props.setDisabled(false);
      }
    } else if (step === 5) {
      if (event.date && event.time) {
        props.setDisabled(true);
      } else {
        props.setDisabled(false);
      }
    } else if (step === 6) {
      if (event.description) {
        props.setDisabled(true);
      } else {
        props.setDisabled(false);
      }
    }
  }, [event, step]);

  const handleChangeBox = (type) => {
    setEvent({ ...event, type: type });
  };

  const handleChangeImage = (image) => {
    setEvent({ ...event, image: image });
  };

  const handleChange = (e) => {
    setEvent({ ...event, [e.target.name]: e.target.value });
  };

  const viewEvent = () => {
    setStep(1);
    history.push("/hub/event/" + event.signUpId);
  };

  const addAnotherEvent = (e) => {
    setStep(1);
    setEvent({
      ...event,
      type: null,
      _id: null,
      name: null,
      nameOfLocation: null,
      address: null,
      date: null,
      description: null,
      time: null,
    });
  };

  switch (step) {
    case 1:
      return (
        <div>
          <motion.div initial="hidden" animate="visible" variants={variants} transition={{ ease: "easeIn", duration: 0.5 }}>
            <h4 className=" pb-2 ">What kind of event will you be creating for the signup?</h4>
            <Container fluid>
              {/* Table */}
              <Row>
                <Col xs="6" sm="6" lg="6" xl="6">
                  <Card
                    className={1 === active ? "active-box" : null}
                    onClick={() => {
                      setActive(1);
                      handleChangeBox("Sports");
                    }}
                  >
                    <CardBody className="text-center shadow">
                      <h1>
                        <i className="fas fa-running"></i>
                      </h1>
                      <h5 style={{ fontWeight: "700", fontSize: "11px", color: "#525F7F" }}>Sports</h5>
                    </CardBody>
                  </Card>
                </Col>
                <Col xs="6" sm="6" lg="6" xl="6">
                  <Card
                    className={2 === active ? "active-box" : null}
                    onClick={() => {
                      setActive(2);
                      handleChangeBox("Volunteer");
                    }}
                  >
                    <CardBody className="text-center shadow">
                      <h1>
                        <i className="fas fa-hands-helping"></i>
                      </h1>
                      <h5 style={{ fontWeight: "700", fontSize: "11px" }}>Volunteer</h5>
                    </CardBody>
                  </Card>
                </Col>
                <Col className="mt-4" xs="6" sm="6" lg="6" xl="6">
                  <Card
                    className={3 === active ? "active-box" : null}
                    onClick={() => {
                      setActive(3);
                      handleChangeBox("Party");
                    }}
                  >
                    <CardBody className="text-center shadow">
                      <h1>
                        <i className="fas fa-glass-cheers"></i>
                      </h1>
                      <h5 style={{ fontWeight: "700", fontSize: "11px", color: "#525F7F" }}>Party</h5>
                    </CardBody>
                  </Card>
                </Col>
                <Col className="mt-4" xs="6" sm="6" lg="6" xl="6">
                  <Card
                    className={4 === active ? "active-box" : null}
                    onClick={() => {
                      setActive(4);
                      handleChangeBox("Other");
                    }}
                  >
                    <CardBody className="text-center shadow">
                      <h1>
                        <i className="fas fa-align-justify"></i>
                      </h1>
                      <h5 style={{ fontWeight: "700", fontSize: "11px", color: "#525F7F" }}>Other</h5>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </motion.div>
        </div>
      );
    case 2:
      return (
        <motion.div initial="hidden" animate="visible" variants={variants} transition={{ ease: "easeIn", duration: 0.5 }}>
          <div>
            <div>
              <h4 className=" pb-2 ">Pick an epic background picture for the event!</h4>
              <Container fluid>
                {/* Table */}

                {event.type === "Sports" ? (
                  <Row>
                    <Col xs="6" sm="6" lg="6" xl="6">
                      <Card
                        onClick={() => {
                          setActiveImage(1);
                          handleChangeImage("https://res.cloudinary.com/dmcuruoxf/image/upload/v1609967402/sports2_hxlqsf.jpg");
                        }}
                        className="shadow"
                      >
                        <div
                          className={1 === activeImage ? "active-image" : null}
                          style={{ boxShadow: "1px 2px 3px #333", borderRadius: "10px" }}
                        >
                          <img
                            style={{ width: "100%", height: "100px", objectFit: "cover", borderRadius: "10px" }}
                            src={"https://res.cloudinary.com/dmcuruoxf/image/upload/v1609967402/sports2_hxlqsf.jpg"}
                          />
                        </div>
                      </Card>
                    </Col>
                    <Col xs="6" sm="6" lg="6" xl="6">
                      <Card
                        onClick={() => {
                          setActiveImage(2);
                          handleChangeImage("https://res.cloudinary.com/dmcuruoxf/image/upload/v1609967403/sports_epsoy8.jpg");
                        }}
                        className="shadow"
                      >
                        <div
                          className={2 === activeImage ? "active-image" : null}
                          style={{ boxShadow: "1px 2px 3px #333", borderRadius: "10px" }}
                        >
                          <img
                            style={{ width: "100%", height: "100px", objectFit: "cover", borderRadius: "10px" }}
                            src={"https://res.cloudinary.com/dmcuruoxf/image/upload/v1609967403/sports_epsoy8.jpg"}
                          />
                        </div>
                      </Card>
                    </Col>{" "}
                  </Row>
                ) : event.type === "Party" ? (
                  <Row>
                    <Col xs="6" sm="6" lg="6" xl="6">
                      <Card
                        onClick={() => {
                          setActiveImage(1);
                          handleChangeImage("https://res.cloudinary.com/dmcuruoxf/image/upload/v1610378690/party_svbwje.jpg");
                        }}
                        className="shadow"
                      >
                        <div
                          className={1 === activeImage ? "active-image" : null}
                          style={{ boxShadow: "1px 2px 3px #333", borderRadius: "10px" }}
                        >
                          <img
                            style={{ width: "100%", height: "100px", objectFit: "cover", borderRadius: "10px" }}
                            src={"https://res.cloudinary.com/dmcuruoxf/image/upload/v1610378690/party_svbwje.jpg"}
                          />
                        </div>
                      </Card>
                    </Col>
                    <Col xs="6" sm="6" lg="6" xl="6">
                      <Card
                        onClick={() => {
                          setActiveImage(2);
                          handleChangeImage("https://res.cloudinary.com/dmcuruoxf/image/upload/v1610378690/party3_bqzdlb.jpg");
                        }}
                        className="shadow"
                      >
                        <div
                          className={2 === activeImage ? "active-image" : null}
                          style={{ boxShadow: "1px 2px 3px #333", borderRadius: "10px" }}
                        >
                          <img
                            style={{ width: "100%", height: "100px", objectFit: "cover", borderRadius: "10px" }}
                            src={"https://res.cloudinary.com/dmcuruoxf/image/upload/v1610378690/party3_bqzdlb.jpg"}
                          />
                        </div>
                      </Card>
                    </Col>
                    <Col className="mt-3" xs="6" sm="6" lg="6" xl="6">
                      <Card
                        onClick={() => {
                          setActiveImage(3);
                          handleChangeImage("https://res.cloudinary.com/dmcuruoxf/image/upload/v1610378691/party2_fslx9a.jpg");
                        }}
                        className="shadow"
                      >
                        <div
                          className={3 === activeImage ? "active-image" : null}
                          style={{ boxShadow: "1px 2px 3px #333", borderRadius: "10px" }}
                        >
                          <img
                            style={{ width: "100%", height: "100px", objectFit: "cover", borderRadius: "10px" }}
                            src={"https://res.cloudinary.com/dmcuruoxf/image/upload/v1610378691/party2_fslx9a.jpg"}
                          />
                        </div>
                      </Card>
                    </Col>
                  </Row>
                ) : event.type === "Volunteer" ? (
                  <Row>
                    <Col xs="6" sm="6" lg="6" xl="6">
                      <Card
                        onClick={() => {
                          setActiveImage(1);
                          handleChangeImage("https://res.cloudinary.com/dmcuruoxf/image/upload/v1610378690/volunteer1_k3uohv.jpg");
                        }}
                        className="shadow"
                      >
                        <div
                          className={1 === activeImage ? "active-image" : null}
                          style={{ boxShadow: "1px 2px 3px #333", borderRadius: "10px" }}
                        >
                          <img
                            style={{ width: "100%", height: "100px", objectFit: "cover", borderRadius: "10px" }}
                            src={"https://res.cloudinary.com/dmcuruoxf/image/upload/v1610378690/volunteer1_k3uohv.jpg"}
                          />
                        </div>
                      </Card>
                    </Col>
                    <Col xs="6" sm="6" lg="6" xl="6">
                      <Card
                        onClick={() => {
                          setActiveImage(2);
                          handleChangeImage("https://res.cloudinary.com/dmcuruoxf/image/upload/v1610378691/volunteer_ppmdvd.jpgg");
                        }}
                        className="shadow"
                      >
                        <div
                          className={2 === activeImage ? "active-image" : null}
                          style={{ boxShadow: "1px 2px 3px #333", borderRadius: "10px" }}
                        >
                          <img
                            style={{ width: "100%", height: "100px", objectFit: "cover", borderRadius: "10px" }}
                            src={"https://res.cloudinary.com/dmcuruoxf/image/upload/v1610378691/volunteer_ppmdvd.jpg"}
                          />
                        </div>
                      </Card>
                    </Col>
                  </Row>
                ) : event.type === "Other" ? (
                  <div>
                    <Row>
                      <Col xs="6" sm="6" lg="6" xl="6">
                        <Card
                          onClick={() => {
                            setActiveImage(1);
                            handleChangeImage("https://res.cloudinary.com/dmcuruoxf/image/upload/v1609967402/sports2_hxlqsf.jpg");
                          }}
                          className="shadow"
                        >
                          <div
                            className={1 === activeImage ? "active-image" : null}
                            style={{ boxShadow: "1px 2px 3px #333", borderRadius: "10px" }}
                          >
                            <img
                              style={{ width: "100%", height: "100px", objectFit: "cover", borderRadius: "10px" }}
                              src={"https://res.cloudinary.com/dmcuruoxf/image/upload/v1609967402/sports2_hxlqsf.jpg"}
                            />
                          </div>
                        </Card>
                      </Col>
                      <Col xs="6" sm="6" lg="6" xl="6">
                        <Card
                          onClick={() => {
                            setActiveImage(2);
                            handleChangeImage("https://res.cloudinary.com/dmcuruoxf/image/upload/v1609967403/sports_epsoy8.jpg");
                          }}
                          className="shadow"
                        >
                          <div
                            className={2 === activeImage ? "active-image" : null}
                            style={{ boxShadow: "1px 2px 3px #333", borderRadius: "10px" }}
                          >
                            <img
                              style={{ width: "100%", height: "100px", objectFit: "cover", borderRadius: "10px" }}
                              src={"https://res.cloudinary.com/dmcuruoxf/image/upload/v1609967403/sports_epsoy8.jpg"}
                            />
                          </div>
                        </Card>
                      </Col>{" "}
                    </Row>
                    <Row>
                      <Col className="mt-3" xs="6" sm="6" lg="6" xl="6">
                        <Card
                          onClick={() => {
                            setActiveImage(3);
                            handleChangeImage("https://res.cloudinary.com/dmcuruoxf/image/upload/v1610378690/volunteer1_k3uohv.jpg");
                          }}
                          className="shadow"
                        >
                          <div
                            className={3 === activeImage ? "active-image" : null}
                            style={{ boxShadow: "1px 2px 3px #333", borderRadius: "10px" }}
                          >
                            <img
                              style={{ width: "100%", height: "100px", objectFit: "cover", borderRadius: "10px" }}
                              src={"https://res.cloudinary.com/dmcuruoxf/image/upload/v1610378690/volunteer1_k3uohv.jpg"}
                            />
                          </div>
                        </Card>
                      </Col>
                      <Col className="mt-3" xs="6" sm="6" lg="6" xl="6">
                        <Card
                          onClick={() => {
                            setActiveImage(4);
                            handleChangeImage("https://res.cloudinary.com/dmcuruoxf/image/upload/v1610378691/volunteer_ppmdvd.jpgg");
                          }}
                          className="shadow"
                        >
                          <div
                            className={4 === activeImage ? "active-image" : null}
                            style={{ boxShadow: "1px 2px 3px #333", borderRadius: "10px" }}
                          >
                            <img
                              style={{ width: "100%", height: "100px", objectFit: "cover", borderRadius: "10px" }}
                              src={"https://res.cloudinary.com/dmcuruoxf/image/upload/v1610378691/volunteer_ppmdvd.jpg"}
                            />
                          </div>
                        </Card>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="mt-3" xs="6" sm="6" lg="6" xl="6">
                        <Card
                          onClick={() => {
                            setActiveImage(5);
                            handleChangeImage("https://res.cloudinary.com/dmcuruoxf/image/upload/v1610378690/party_svbwje.jpg");
                          }}
                          className="shadow"
                        >
                          <div
                            className={5 === activeImage ? "active-image" : null}
                            style={{ boxShadow: "1px 2px 3px #333", borderRadius: "10px" }}
                          >
                            <img
                              style={{ width: "100%", height: "100px", objectFit: "cover", borderRadius: "10px" }}
                              src={"https://res.cloudinary.com/dmcuruoxf/image/upload/v1610378690/party_svbwje.jpg"}
                            />
                          </div>
                        </Card>
                      </Col>
                      <Col className="mt-3" xs="6" sm="6" lg="6" xl="6">
                        <Card
                          onClick={() => {
                            setActiveImage(6);
                            handleChangeImage("https://res.cloudinary.com/dmcuruoxf/image/upload/v1610378690/party3_bqzdlb.jpg");
                          }}
                          className="shadow"
                        >
                          <div
                            className={6 === activeImage ? "active-image" : null}
                            style={{ boxShadow: "1px 2px 3px #333", borderRadius: "10px" }}
                          >
                            <img
                              style={{ width: "100%", height: "100px", objectFit: "cover", borderRadius: "10px" }}
                              src={"https://res.cloudinary.com/dmcuruoxf/image/upload/v1610378690/party3_bqzdlb.jpg"}
                            />
                          </div>
                        </Card>
                      </Col>
                      <Col className="mt-3" xs="6" sm="6" lg="6" xl="6">
                        <Card
                          onClick={() => {
                            setActiveImage(7);
                            handleChangeImage("https://res.cloudinary.com/dmcuruoxf/image/upload/v1610378691/party2_fslx9a.jpg");
                          }}
                          className="shadow"
                        >
                          <div
                            className={7 === activeImage ? "active-image" : null}
                            style={{ boxShadow: "1px 2px 3px #333", borderRadius: "10px" }}
                          >
                            <img
                              style={{ width: "100%", height: "100px", objectFit: "cover", borderRadius: "10px" }}
                              src={"https://res.cloudinary.com/dmcuruoxf/image/upload/v1610378691/party2_fslx9a.jpg"}
                            />
                          </div>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                ) : null}
              </Container>
            </div>
          </div>
        </motion.div>
      );
    case 3:
      return (
        <motion.div initial="hidden" animate="visible" variants={variants} transition={{ ease: "easeIn", duration: 0.5 }}>
          <div>
            <div>
              <h4 className=" pb-2 ">What's the name of your event you are organizing?</h4>
              <Input onChange={handleChange} name="name" value={event.name} type="text" placeholder="Ex: Ugly Sweater Christmas Party" />
            </div>
          </div>
        </motion.div>
      );
    case 4:
      return (
        <div>
          {" "}
          <motion.div initial="hidden" animate="visible" variants={variants} transition={{ ease: "easeIn", duration: 0.5 }}>
            <h4 className="pb-2">What's a good name for the location of the event?</h4>
            <Input
              onChange={handleChange}
              name="nameOfLocation"
              value={event.nameOfLocation}
              type="text"
              placeholder="Ex: Oak Grove Soccer Fields"
            />
            <h4 className="pb-2 pt-3">What's the address for the event? (Please use full address for best accuracy)</h4>
            <Input
              onChange={handleChange}
              name="address"
              type="textarea"
              value={event.address}
              placeholder="Ex: 19900 Nightingale St NW, Oak Grove, MN 55011"
            />
          </motion.div>
        </div>
      );
    case 5:
      return (
        <div>
          <motion.div initial="hidden" animate="visible" variants={variants} transition={{ ease: "easeIn", duration: 0.5 }}>
            <h4 className=" pb-2 ">What's the date of the event?</h4>
            <Input
              onChange={handleChange}
              value={event.date}
              name="date"
              type="date"
              name="date"
              id="exampleDate"
              placeholder="date placeholder"
            />
            <h4 className=" pb-2 pt-3">What time will the event start?</h4>
            <Input
              onChange={handleChange}
              name="time"
              value={event.time}
              type="time"
              name="time"
              id="exampleTime"
              placeholder="time placeholder"
            />
            <h4 className=" pb-2 pt-3">What time will the event end? (If no end date, leave the field empty)</h4>
            <Input
              onChange={handleChange}
              name="time"
              value={event.timeEnd}
              type="time"
              name="time"
              id="exampleTime"
              placeholder="time placeholder"
            />
          </motion.div>
        </div>
      );
    case 6:
      return (
        <div>
          <motion.div initial="hidden" animate="visible" variants={variants} transition={{ ease: "easeIn", duration: 0.5 }}>
            <h4 className="pb-2 ">Type an eye-catching description for the event!</h4>
            <Input onChange={handleChange} name="description" value={event.description} className="description" type="textarea" />
          </motion.div>
        </div>
      );

      {
        /*case 7:
      return (
        <div>
          <motion.div initial="hidden" animate="visible" variants={variants} transition={{ ease: "easeIn", duration: 0.5 }}>
            <h2>Let's review what you came up with!</h2>
            <h5 className=" text-muted text-small">
              <strong>Name: </strong>
              {event.name}
            </h5>
            <h5 className=" text-muted text-small">
              <strong>Type: </strong>
              {event.type}
            </h5>
            <h5 className=" text-muted text-small">
              <strong>Location name: </strong>
              {event.nameOfLocation}
            </h5>
            <h5 className="text-muted text-small">
              <strong>Location address: </strong>
              {event.address}
            </h5>
            <h5 className="text-muted text-small">
              <strong>Date: </strong>
              {event.date}
            </h5>
            <h5 className="text-muted text-small">
              <strong>Time: </strong>
              {event.time}
            </h5>
            <h5 className="text-muted text-small">
              <strong>Description: </strong>
              {event.description}
            </h5>
          </motion.div>
        </div>
      );*/
      }
    case 7:
      return (
        <div>
          <motion.div initial="hidden" animate="visible" variants={variants} transition={{ ease: "easeIn", duration: 0.5 }}>
            <h4>Your Event has been created!</h4>
            <p style={{ fontSize: ".8em" }} className="text-muted">
              Next step? View your signup, tap the copy button, and share it with your friends!{" "}
            </p>
            <Button onClick={viewEvent} outline block color="danger">
              View Event
            </Button>
            <p style={{ fontSize: ".8em" }} className="mt-3">
              Do you want to combine this event with another event to your signup?{" "}
              <span className="text-muted">(You can do this at any time.)</span>
            </p>
            <Button color="danger" outline block onClick={addAnotherEvent}>
              Add Another Event
            </Button>

            <Button color="warning" className="mt-4" outline block href="/admin/index">
              Back to Dashboard
            </Button>
          </motion.div>
        </div>
      );

    default:
      break;
  }
};

export default Steps;
